
.button__section > button{
  margin: 10px;
  padding: 15px 45px;
  
}

.loading_spinner_wrapper{
  position: relative;
  width: 100%;
  height: 100vh;
  background: #000;
  left: 0;
   
}
.spinner_body{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}