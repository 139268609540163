.sidebar {
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  width: 14rem;
  height: 95%;
  /* justify-content: start;
  align-items: start; */
  flex-direction: column;
  padding: 15px;
  margin: 10px;
  z-index: 9999;
}

/* Dark mode */
.dark .sidebar {
  background: rgba(10, 12, 20, 0.764);
}
@media (prefers-color-scheme: dark) {
  .dark .sidebar {
    background: rgba(10, 12, 20, 0.764);
  }
}

a {
  text-decoration: none;
  display: block;
  color: white;
}

ul {
  list-style: none;
}

.nav__btns,
.nav__close {
  visibility: hidden;
}

/***
 small screen 
 ***/

@media screen and (max-width: 768px) {
  .sidebar {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 8%;
    align-items: stretch;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: hsl(250, 60%, 98%);
    border-radius: 1rem 1rem 0 0;
  }

  .nav.container {
    min-width: 100px;
    padding: 0 35px;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav__logo,
  .nav__toggle {
    color: hsl(250, 8%, 15%);
    font-weight: 700;
  }
  .nav__logo:hover {
    color: hsl(0, 69%, 61%);
  }
  .nav__btns {
    display: flex;
    gap: 15px;
    align-items: center;
    visibility: visible;
  }

  .theme-customization {
    font-size: 1.25rem;
    color: hsl(250, 8%, 15%);
    margin-right: 1rem;
    cursor: pointer;
  }

  .nav__toggle:hover,
  .theme-customization:hover {
    color: hsl(0, 69%, 61%);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background: hsl(250, 60%, 98%);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.2rem 1.2rem 0 0;
    transition: 0.3s;
    z-index: 9999;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  .nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: hsl(252, 10%, 10%);
    font-weight: 500;
  }
  .nav__link:hover {
    color: hsl(0, 69%, 61%);
  }
  .nav__icon {
    font-size: 1.2rem;
  }
  .nav__close {
    visibility: visible;
    position: absolute;
    right: 1.2rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: hsl(250, 8%, 15%);
  }

  .nav__profile {
    position: fixed;
    display: flex;
    gap: 17px;
    bottom: 15px;
    left: 40%;
  }

  .circle {
    position: fixed;
    bottom: 21px;
    left: 46%;
    height: 3.2rem;
    width: 3.2rem;
    background: rgb(255, 255, 255);
    /* background: #000; */
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border-radius: 50%;
    z-index: -66;
  }
  .profile__image {
    /* visibility: hidden; */
    /* bottom: 10px; */
    /* left: 40%; */
    position: fixed;
    bottom: 23px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    object-fit: cover;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
    cursor: pointer;
    /* margin-right: 50px; */
    z-index: 5;
    box-lines: var(--profileShadow);
  }
  .nav__profile > button {
    visibility: hidden;
  }
  .LinkUnset__hover {
    color: gray;
  }
  /* Dark mode */
  .dark .nav__menu {
    background: rgba(10, 12, 20, 1);
  }
  .dark .nav__link,
  .nav__close,
  .nav__toggle {
    color: hsl(0, 2%, 65%);
  }
  @media (prefers-color-scheme: dark) {
    .dark .nav__menu {
      background: rgba(10, 12, 20, 1);
    }
    .dark .nav__link,
    .nav__close,
    .nav__toggle {
      color: hsl(0, 2%, 65%);
    }
  }
}

/***
 Large screen 
 ***/
@media screen and (min-width: 769px) {
  .nav__logo {
    font-weight: 900;
    font-size: 30px;
    color: gray;
    padding: 30px;
  }
  a {
    text-decoration: none;
    display: block;
    color: gray;
    font-weight: 700;
    padding: 12px;
    height: 50px;
  }

  a i {
    font-size: 20px;
    font-weight: 900;
    margin-right: 4px;
  }

  a:hover {
    background: var(--buttonBg);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 167px;
    color: white;
  }

  .LinkUnset__hover:hover {
    background: unset;
    box-shadow: unset;
    color: unset;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
    border-radius: unset;
    border: unset;
    width: unset;
  }

  .active {
    /* text-decoration: none;
    color: blue;
    font-size: 40px; */
    background: var(--buttonBg);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 167px;
    color: white;
  }
  ul {
    list-style: none;
  }

  ul li {
    color: #cce5ff;
    margin: 15px;
  }
  .nav__profile {
    position: fixed;
    bottom: 50px;
    display: flex;

    gap: 20px;
    align-items: center;
    margin-left: 40px;
  }

  .profile__image {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    cursor: pointer;
    /* margin-right: 50px; */
    box-lines: var(--profileShadow);
  }
  /* .nav__profile > button {
    height: 2.5rem;
    width: 4rem;
    box-lines: var(--profileShadow);
  } */
}
