.wrapper{
height: 100vh;
width: 100%;
display: grid;
grid-template-columns: 1fr 1fr;
}
.logo__side{
/* background: rgb(177, 172, 172); */
display: flex;
justify-content: center;
align-items: center;

}
.form__side{
/* background: red; */
display: flex;
justify-content: center;
align-items: center;

}



/* Extra small devices (phones, 600px and down) */
@media  screen and (max-width: 768px) {
    .wrapper{
        display: flex;
        flex-direction: column;
}
.logo__side{
    height: 40%;
    /* background: rgb(60, 68, 60); */
}
.logo__side img{
    width: 200px;
    height: 300px;
    object-fit: cover;
}
.form__side{
    height: 100%;
    /* background: rgb(133, 170, 103);  */
}
}



/* Medium devices (landscape tablets, 768px and up) */
@media  screen and (min-width: 768px) {
    .wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        }

}
