.PostSide {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  /* overflow: auto; */
  /* background: white; */
  border-radius: 10px;
}

/* @media screen and (max-width: 769px) {
  .headerContainer{
    width: calc(100% - 10%);
  }
} */
